




































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Contact, SMS } from "@/models/Sms";
import Vue from "vue";
import rules from "@/components/account/rules";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import BtnMakeCall from "../CallCenter/buttons/BtnMakeCall.vue";
import formatPhone from "@/utils/formatPhone";
import { getAPI } from "@/api/axios-base";

export default Vue.extend({
  name: "chat-person-list",
  components: { BtnMakeCall },
  data() {
    return {
      formValid: false,
      rules: {
        email: rules.email,
        required: rules.required,
        phone: rules.isPhone,
      },
      addcontact: {
        dialogAddContact: false,
        item: { alias: "", number: "", origin: "Lead" },
      },
      selectedPerson: null,
      showOnline: false,
      interval: null,
      loading: false,
      chatHeight: 0,

      loadempl: false,
      query: "",
    };
  },
  computed: {
    ...mapState("crmCallCenterModule", ["dialogmakeCall"]),
    ...mapGetters("crmSMSModule", ["messagesPerPatient"]),
    ...mapState(["profile", "loadingContact", "activesms"]),
    ...mapGetters(["isAdmin", "isSuper", "getPhone"]),
    ...mapState("crmSMSModule", [
      "messages",
      "contacts",
      "unreads",
      "contactSelect",
      "numberSelect",
    ]),

    _numbercontact() {
      if (this.contactSelect == null) {
        return "";
      }
      return this.contactSelect.number;
    },

    contactSmsList() {
      return Array.from(this.messagesPerPatient.keys());
    },

    isAdministrator() {
      return this.isAdmin || this.isSuper;
    },
  },
  async mounted() {
    (this as any)._getNumberContacts();
  },
  destroyed() {
    (this as any).mutnumberSelect(null);
    (this as any).mutSetContact(null);
    clearInterval((this as any).interval);
  },

  watch: {
    selectedPerson(val) {
      if (val != undefined && val != null) {
        const contac = this.contacts.filter((c: Contact) => c.number == val);
        if (contac.length > 0) {
          (this as any).setContact(contac[0]);
        }
      }
    },
    activesms(val) {
      if (val) {
        (this as any)._getNumberContacts();
      }
    },
    /* contactSelect: {
      handler() {
        (this as any).getsmsFiltered();
      },
    }, */
  },

  methods: {
    ...mapActions("crmSMSModule", ["actContac"]),
    ...mapActions(["actCreateContacts"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapMutations("crmSMSModule", [
      "mutSetContact",
      "mutnumberSelect",
      "mutcontacts",
      "mutSetMessagesFilters",
    ]),
    ...mapMutations(["updateactivesms"]),
    ...mapMutations("crmCallCenterModule", ["mutDialogmakeCall"]),
    openCall(phone: any) {
      localStorage.setItem("number", phone);
      (this as any).mutDialogmakeCall(true);
    },

    makeCall(number: any) {
      getAPI("/zoom/zoomPhonecall", {
        params: {
          phone: number,
        },
      }).then((res) => {
        window.open(res.data, "_blank", "noreferrer");
      });
    },

    resetFilter() {
      (this as any).mutSetContact(null);
    },

    setHeight() {
      (this as any).chatHeight = this.$vuetify.breakpoint.height;
    },

    async _getNumberContacts() {
      let uuid = (this as any).profile ? (this as any).profile.uuid : null;
      if (uuid != null) {
        await (this as any).actContac(uuid);
        (this as any).updateactivesms(false);
      } else {
        (this as any).mutcontacts([]);
      }
    },
    noReadSms(contact: Contact) {
      const noread = this.unreads.filter(
        (ms: any) => ms.from == contact.number
      );
      if (noread.length != 0) {
        return noread[0].cant;
      }
      return 0;
    },

    setContact(account: Contact) {
      (this as any).mutSetContact(account);
      this.$emit("contact", account);
    },
    gotoAdd(item: any) {
      let num = item.number;
      num = num.replace("+1", "");
      num = num.replaceAll("(", "");
      num = num.replaceAll(")", "");
      num = num.replaceAll("-", "");
      num = num.replaceAll(" ", "");
      num = num.replaceAll("\t", "");
      num = formatPhone(num);

      (this as any).addcontact = {
        dialogAddContact: true,
        item: {
          alias: item.name,
          number: num,
          origin: item.origin ? item.origin : "Lead",
        },
      };
    },
    async confirmAdd() {
      let body = (this as any).addcontact.item;
      let num = body.number;
      num = num.replace("+1", "");
      num = num.replaceAll("(", "");
      num = num.replaceAll(")", "");
      num = num.replaceAll("-", "");
      num = num.replaceAll(" ", "");
      num = num.replaceAll("\t", "");
      num = formatPhone(num);
      body.number = "+1" + num;
      await (this as any).actCreateContacts(body);
      (this as any).cancelAdd();
    },

    cancelAdd() {
      (this as any).addcontact = {
        dialogAddContact: false,
        item: { alias: "", number: "", origin: "Lead" },
      };
    },

    gotoPatient(patientuuid: string) {
      this.$router.push(`/clients/details/${patientuuid}`);
    },
  },
});
