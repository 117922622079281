var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.setChatHeight),expression:"setChatHeight"}],ref:"form",staticClass:"d-flex flex-column fill-height position-relative smsform-wrappper",style:({
    'max-height': ((_vm.chatHeight - 44) + "px"),
  }),model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[(_vm.contactSelect == null)?[(_vm.contactSelect == undefined || _vm.contactSelect == null)?_c('message-list-header'):_vm._e()]:[_c('v-btn',{staticClass:"mx-2 mt-2",attrs:{"fab":"","dark":"","small":"","color":"cyan"},on:{"click":_vm.newContact}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1)],_c('div',{staticClass:"d-flex"},[_c('div',[(_vm.contactSelect != undefined && _vm.contactSelect != null)?_c('h3',{staticClass:"mt-2 ml-2"},[_vm._v(" "+_vm._s("To: " + _vm.contactSelect.name != undefined ? _vm.contactSelect.name : _vm.contactSelect.number)+" ")]):_vm._e(),(_vm.contactSelect != undefined && _vm.contactSelect != null)?_c('h4',{staticClass:"ma-1 text--secondary"},[_vm._v(" "+_vm._s(_vm.contactSelect.number)+" ")]):_vm._e()]),_c('v-spacer'),(_vm.contactSelect != undefined && _vm.contactSelect != null && _vm.contactSelect.origin == 'Patient')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"color":"info","text":""},on:{"click":function($event){return _vm.gotoPatient(_vm.contactSelect.patient_uuid)}}},on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,4105770904)},[_c('span',[_vm._v("Client Details")])])]:_vm._e()],2),_c('v-divider'),_c('div',{staticClass:"\n      message-list-container\n      flex-column\n      justify-end\n      fill-height\n      overflow-y-auto\n    "},[_vm._v(" "+_vm._s(/** List messages */)+" "),(_vm.contactSelect == undefined || _vm.contactSelect == null)?_c('div',{staticClass:"d-flex flex-column align-start justify-start px-2 py-2"},[_vm._v(" Select contact ")]):_vm._e(),(_vm.contactSelect != undefined && _vm.contactSelect != null)?_c('div',{ref:"smscontentScroll",staticClass:"smscontent px-2 pb-2",style:({
        height: ((_vm.chatHeight - 285) + "px"),
      })},[(_vm.loadingbtw)?[_c('div',{staticClass:"d-flex justify-center my-4"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]:_vm._e(),(_vm.moreSms && !_vm.loadingbtw)?[_c('div',{staticClass:"d-flex justify-center container-more"},[_c('v-btn',{attrs:{"text":"","color":"info"},on:{"click":_vm.getMore}},[_c('v-icon',[_vm._v("mdi-message-processing")]),_vm._v(" more sms")],1)],1)]:_vm._e(),_vm._l((_vm.messagesFiltered),function(message,index){return [(_vm.isDiferentMessage(index, _vm.messagesFiltered))?_c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple"}],key:'s-' + index,staticClass:"mt-2 px-2 d-flex align-center cursor-pointer",class:{
            'justify-end': _vm.isSend(message),
            'ml-auto': _vm.isSend(message),
          },staticStyle:{"min-height":"24px","top":"0","z-index":"1"},attrs:{"color":"var(--crm-background)","rounded":"","small":""}},[_c('span',{staticClass:"text-caption font-weight-bold grey--text darken-4"},[_vm._v(" "+_vm._s(_vm.formatDate(message.createAt))+" ")])]):_vm._e(),(_vm.contactSelect != undefined)?_c('message',{key:index,attrs:{"messageProps":Object.assign({}, message,
          {first: _vm.isFirst(index, _vm.messagesFiltered),
          last: _vm.isLast(index, _vm.messagesFiltered),
          middle:
            _vm.isMiddle(index, _vm.messagesFiltered) ||
            (_vm.isFirst(index, _vm.messagesFiltered) &&
              _vm.isFirst(index, _vm.messagesFiltered))})}}):_vm._e()]})],2):_vm._e()]),(_vm.contactSelect != null)?[_vm._v(" "+_vm._s(/* To send messages */)+" "),_c('message-input',{attrs:{"loading":_vm.loadingSendMessage},on:{"send-message":function($event){return _vm.getsmsFiltered(0)}}}),(_vm.loadingbtw)?_c('v-progress-linear',{attrs:{"color":"green accent-3","absolute":"","bottom":"","fixed":"","indeterminate":""}}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }